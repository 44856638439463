
/*  1px solid red; */
:root{
  --main-border-type: none;
  --main-hover-row: #eef6fa;
}

.rc-table {
  position: relative;
  box-sizing: border-box;
  color: #666;
  font-size: 12px;
  line-height: 1.5;
}
.rc-table-rtl {
  direction: rtl;
}
.rc-table table {
  border: none;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.rc-table thead th {
  
}
.rc-table tbody td {
  border-top: 1px solid #d0d4e3;
  border-bottom: 1px solid #d0d4e3;
}
.rc-table tbody td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid #d0d4e3;
}
.rc-table tbody td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid #d0d4e3;
}
.rc-table tbody tr {
  height: 45px !important;
}

.rc-table th, .rc-table td {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  padding: 16px 8px;
  white-space: normal;
  word-break: break-word;
  border: var(--main-border-type);
  border-top: 0;
  border-left: 0;
  transition: box-shadow 0.3s;
}
.rc-table-rtl.rc-table th, .rc-table-rtl.rc-table td {
  border-right: 0;
  border-left:var(--main-border-type);
}
.rc-table-cell-fix-left, .rc-table-cell-fix-right {
  z-index: 1;
}
.rc-table-cell-fix-right:last-child:not(.rc-table-cell-fix-sticky) {
  border-right-color: transparent;
}
.rc-table-rtl .rc-table-cell-fix-right:last-child {
  border-right-color: red;
}
.rc-table-rtl .rc-table-cell-fix-left:last-child {
  border-left-color: transparent;
}
.rc-table-rtl .rc-table-cell-fix-left-first {
  box-shadow: 1px 0 0 red;
}
.rc-table-cell-fix-left-first::after, .rc-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: -1px;
  width: 20px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.rc-table-cell-fix-left-all::after {
  display: none;
}
.rc-table-cell-fix-right-first, .rc-table-cell-fix-right-last {
  box-shadow: -1px 0 0 red;
}
.rc-table-rtl .rc-table-cell-fix-right-first, .rc-table-rtl .rc-table-cell-fix-right-last {
  box-shadow: none;
}
.rc-table-cell-fix-right-first::after, .rc-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -1px;
  width: 20px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.rc-table-cell.rc-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first, .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last, .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last {
  overflow: visible;
}
.rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-first .rc-table-cell-content, .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-left-last .rc-table-cell-content, .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-first .rc-table-cell.rc-table-cell-ellipsis.rc-table-cell-fix-right-last .rc-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rc-table-cell.rc-table-cell-row-hover {
  background: var(--main-hover-row);
}
.rc-table-ping-left .rc-table-cell-fix-left-first::after, .rc-table-ping-left .rc-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px green;
}
.rc-table-ping-right .rc-table-cell-fix-right-first::after, .rc-table-ping-right .rc-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px green;
}
.rc-table-expand-icon-col {
  width: 60px;
}
.rc-table-row-expand-icon-cell {
  text-align: center;
}
.rc-table thead td, .rc-table thead th {
  text-align: center;
  background: none;
}
.rc-table thead .rc-table-cell-scrollbar::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  width: 1px;
  background: #f7f7f7;
  content: '';
}
.rc-table-rtl.rc-table thead .rc-table-cell-scrollbar::after {
  right: -1px;
  left: auto;
}
.rc-table-header {
  border: var(--main-border-type);
  border-right: 0;
  border-bottom: 0;
}
.rc-table-placeholder {
  text-align: center;
}
.rc-table tbody tr td, .rc-table tbody tr th {
  background: #fff;
  padding: 0px;
  padding-left: 5px;
}
.rc-table-content {
  border:var(--main-border-type);
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0 0 0;
}
.rc-table-body {
  border:var(--main-border-type);
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
}
.rc-table-fixed-column .rc-table-body::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-right: var(--main-border-type);
  content: '';
}
.rc-table-expanded-row .rc-table-cell {
  box-shadow: inset 0 8px 8px -8px green;
}
.rc-table-expanded-row-fixed {
  box-sizing: border-box;
  margin: -16px -8px;
  margin-right: -10px;
  padding: 16px 8px;
}
.rc-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  width: 0;
  border-right: var(--main-border-type);
  content: '';
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #aaa;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid currentColor;
  cursor: pointer;
}
.rc-table-row-expand-icon.rc-table-row-expanded::after {
  content: '-';
}
.rc-table-row-expand-icon.rc-table-row-collapsed::after {
  content: '+';
}
.rc-table-row-expand-icon.rc-table-row-spaced {
  visibility: hidden;
}
.rc-table-title {
  padding: 16px 8px;
  border: var(--main-border-type);
  border-bottom: 0;
}
.rc-table-footer {
  padding: 16px 8px;
  border: var(--main-border-type);
  border-top: 0;
}
.rc-table tfoot td {
  background: #fff;
}
.rc-table-summary {
  border-top:var(--main-border-type);
  border-left: var(--main-border-type);
}
.rc-table-sticky-holder {
  position: sticky;
  z-index: 2;
}
.rc-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  opacity: 0.6;
  transition: transform 0.1s ease-in 0s;
}
.rc-table-sticky-scroll:hover {
  transform: scaleY(1.2);
  transform-origin: center bottom;
}
.rc-table-sticky-scroll-bar {
  height: 8px;
  background-color: #bbb;
  border-radius: 4px;
}
.rc-table-sticky-scroll-bar:hover {
  background-color: #999;
}
.rc-table-sticky-scroll-bar-active {
  background-color: #999;
}
